import { nanoid } from 'nanoid';
import _isEqual from 'lodash/isEqual';
import { auth } from 'common/firebase';
import Player from 'entities/players/Player';
import { Roles } from 'entities/access/Access';
import { uploadFile } from 'common/resourses/files';
import * as eventsGateway from 'gateways/eventsGateway';
import Event, { EventTypes } from 'entities/events/Event';
import { actions as eventsActions } from 'entities/events';
import { completePlayerTask } from 'gateways/tasksGateway';
import { ConditionTypes } from 'entities/conditions/Condition';
import { updatePlayerTask } from 'entities/tasks/tasks.actions';
import { PlayerTask, PlayerTaskData } from 'entities/tasks/Task';
import { checkIfEmpty } from 'common/rich-text-editor/utils/helpers';
import { playerDataSelector } from 'entities/players/players.selectors';
import { StorageFileTargetType } from 'entities/storage-files/StorageFile';
import { createNewObservation } from 'entities/observations/observations.utils';
import { createStorageFile } from 'entities/storage-files/storageFiles.actions';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { createPlayerSaga, editPlayerInfoSaga } from 'entities/players/players.sagas';
import { observationDataSelector } from 'entities/observations/observations.selectors';
import { createObservationSaga, updateObservationSaga } from 'entities/observations/observations.sagas';
import Observation, { ObservationAccessType, ObservationData, ObservationType } from 'entities/observations/Observation';
import actionTypes from './playerPage.actionTypes';
import * as playerPageActions from './playerPage.actions';

// here
export function* savePlayerDataSaga({
    playerId,
    organizationId,
    playerData,
    avatarImageFile,
    onSuccess,
    onFail,
}: ReturnType<typeof playerPageActions.savePlayerDataRequest>) {
    let newFileId = null;
    let newFilePath = null;
    let savedPlayerId = playerId;

    try {
        if (playerId === 'new') {
            savedPlayerId = (yield call(createPlayerSaga, {
                playerData,
            })) as string;

            if (avatarImageFile) {
                newFileId = nanoid();
                const fileExtension = avatarImageFile.name.split('.').pop();
                const fileName = `avatar-${newFileId}.${fileExtension}`;

                const { filePath } = yield call(uploadFile, {
                    file: avatarImageFile,
                    filePath: `/players/${savedPlayerId}/${fileName}`,
                });

                newFilePath = filePath;

                yield call(editPlayerInfoSaga, {
                    type: '',
                    playerData: {
                        ...playerData,
                        filePath,
                    },
                    playerId: savedPlayerId,
                    onSuccess: () => {},
                });
            }

            if (onSuccess) {
                yield call(onSuccess, savedPlayerId);
            }
        } else {
            let avatarFilePath = playerData.filePath;

            if (avatarImageFile) {
                newFileId = nanoid();
                const fileExtension = avatarImageFile.name.split('.').pop();
                const fileName = `avatar-${newFileId}.${fileExtension}`;
                const { filePath } = yield call(uploadFile, {
                    file: avatarImageFile,
                    filePath: `/players/${playerId}/${fileName}`,
                });

                newFilePath = filePath;
                avatarFilePath = filePath;
            }

            yield call(editPlayerInfoSaga, {
                type: '',
                playerData: {
                    ...playerData,
                    filePath: avatarFilePath,
                },
                playerId,
                onSuccess: () => {},
            });

            if (onSuccess) {
                yield call(onSuccess, playerId);
            }
        }

        if (newFileId && newFilePath && avatarImageFile) {
            yield put(
                createStorageFile({
                    file: avatarImageFile,
                    filePath: newFilePath,
                    target: { organizationId, playerId: savedPlayerId },
                    targetType: StorageFileTargetType.avatar,
                    storageFileId: newFileId,
                }),
            );
        }
    } catch (e: any) {
        console.error(e);
        if (onFail) {
            yield call(onFail, e.message);
        }
    }
}

// here
export function* saveTeamPlayersSaga({
    players,
    initialPlayers,
    onSuccess,
    onFail,
}: ReturnType<typeof playerPageActions.saveTeamPlayersRequest>) {
    try {
        const playersToUpdate = players.filter((player) => player.id !== 'new');
        const playersToCreate = players.filter((player) => player.id === 'new');

        const createPlayersActions = playersToCreate.map(({ id, ...playerData }) => {
            return call(createPlayerSaga, {
                playerData,
            });
        });

        yield all(createPlayersActions);

        const playersMap = new Map<string, Player>();
        initialPlayers.forEach((player) => playersMap.set(player.id, player));

        const updatePlayersActions = playersToUpdate
            .filter((player) => !_isEqual(player, playersMap.get(player.id)))
            .map(({ id, ...playerData }) => {
                return call(editPlayerInfoSaga, {
                    type: '',
                    playerData,
                    playerId: id,
                    onSuccess: () => {},
                });
            });

        yield all(updatePlayersActions);

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e: any) {
        console.error(e);
        if (onFail) {
            yield call(onFail);
        }
    }
}

const getObservationType = (task: PlayerTask) => {
    const { conditions } = task.details;
    const isWellness = conditions.some((param) => param.conditionType === ConditionTypes.wellness);

    if (isWellness) {
        return ObservationType.wellness;
    }

    const isGame = conditions.some((param) => param.details.eventType === EventTypes.game);

    if (isGame) {
        return ObservationType.game;
    }

    return ObservationType.session;
};

const getObservationTarget = (task: PlayerTask) => {
    const observationType = getObservationType(task);

    if (observationType === ObservationType.wellness) {
        return null;
    }

    const {
        organizationId,
        teamId,
        details: { conditions },
    } = task;

    const { eventId } = conditions[0].details;

    return {
        organizationId,
        teamId,
        eventId,
    };
};

export function* completeTaskSaga({
    task,
    comment,
    taskObservationId,
    onSuccess,
}: ReturnType<typeof playerPageActions.completeTask>) {
    const { playerId, masterTaskId, organizationId, teamId } = task;
    const currentComment: Observation | null = taskObservationId
        ? yield select(observationDataSelector, { observationId: taskObservationId })
        : null;
    let observationId: string | null = taskObservationId || null;
    const hasComment = !checkIfEmpty(comment);

    if (hasComment) {
        if (observationId && currentComment) {
            yield call(updateObservationSaga, {
                type: '',
                observationData: {
                    ...currentComment,
                    content: comment,
                },
                observationId,
                playerId,
                onSuccess: () => {},
            });
        } else {
            const player: Player = yield select(playerDataSelector, { playerId });
            const author = {
                userId: auth.currentUser?.uid!,
                name: [player.lastName, player.firstName].join(' '),
                filePath: player.filePath,
                email: player.email || '',
                role: Roles.player,
            };
            const newObservationData: ObservationData = createNewObservation(
                getObservationType(task),
                ObservationAccessType.all,
                organizationId,
                teamId,
                playerId,
                author,
                getObservationTarget(task),
            );

            observationId = (yield call(createObservationSaga, {
                type: '',
                observationData: {
                    ...newObservationData,
                    content: comment,
                },
                playerId,
                onSuccess: () => {},
            })) as string;
        }
    }

    yield call(completePlayerTask, {
        playerId,
        masterTaskId,
        organizationId,
        teamId,
        observationId,
    });
    const { id, ...restTask } = task;
    const updatedTask: PlayerTaskData = {
        ...restTask,
        completeTime: new Date(),
        observationId,
    };
    yield put(updatePlayerTask({ playerTaskData: updatedTask, playerId, taskId: id }));
    yield call(onSuccess);
}

export function* fetchPlayerEventsSaga({
    playerId,
    teamId,
    organizationId,
    fromDate,
    toDate,
    onSuccess,
    onFail,
}: ReturnType<typeof playerPageActions.fetchPlayerEvents>) {
    try {
        const playerEvents = (yield call([eventsGateway, eventsGateway.fetchPlayerEvents], {
            playerId,
            teamId,
            organizationId,
            fromDate,
            toDate,
        })) as Event[];
        yield put(eventsActions.eventsListReceived(playerEvents));
        yield call(onSuccess);
    } catch (e: any) {
        yield onFail(e?.message || '');
    }
}

function* playerSagas() {
    yield all([
        takeLatest(actionTypes.SAVE_PLAYER_DATA_REQUEST, savePlayerDataSaga),
        takeLatest(actionTypes.SAVE_TEAM_PLAYERS_REQUEST, saveTeamPlayersSaga),
        takeEvery(actionTypes.FETCH_PLAYER_EVENTS, fetchPlayerEventsSaga),
        takeLatest(actionTypes.COMPLETE_TASK, completeTaskSaga),
    ]);
}

export default playerSagas;
