import { setHours } from 'date-fns';
import { Author } from 'entities/users/User';
import { Roles } from 'entities/access/Access';
import Observation, {
    ObservationAccessType,
    ObservationData,
    ObservationTarget,
    ObservationType,
} from './Observation';

export const getObservationText = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.innerText;
};

export const createNewObservation = (
    type: ObservationType,
    accessType: ObservationAccessType,
    organizationId: string,
    teamId: string,
    playerId: string,
    author: Author,
    target?: ObservationTarget | null,
): ObservationData => {
    const createDate = setHours(new Date(), 12);
    return {
        createDate,
        editDate: createDate,
        accessType,
        type,
        teamId,
        organizationId,
        content: '<p></p>',
        playerId,
        author,
        target: target || null,
        attachments: [],
    };
};

export const createEmptyObservation = (
    organizationId: string = 'org-id-1',
    teamId: string = 'team-id-1',
    playerId: string = 'player-id-1',
) => {
    const author = {
        userId: 'creator-id-1',
        filePath: null,
        name: 'user-1',
        email: 'user email',
        role: Roles.coach,
    };
    return createNewObservation(
        ObservationType.general,
        ObservationAccessType.private,
        organizationId,
        teamId,
        playerId,
        author,
    );
};

export const defaultObervationsTypes = [
    ObservationType.general,
    ObservationType.talk,
    // ObservationType.session,
    // ObservationType.game,
];

export const defaultObervationsAccessTypes = [
    ObservationAccessType.private,
    ObservationAccessType.team,
    ObservationAccessType.all,
];

export const defaultObservationConfig = {
    types: defaultObervationsTypes,
    accessTypes: defaultObervationsAccessTypes,
    defaultType: ObservationType.general,
    defaultAccessType: ObservationAccessType.team,
};

export type ObservationConfig = typeof defaultObservationConfig;

export const mapObservationToDatesStrings = (observation: Observation): Observation => {
    const editDate = observation.editDate.toISOString();
    const createDate = observation.createDate.toISOString();
    const mappedObservation = {
        ...observation,
        createDate,
        editDate,
    };

    // @ts-ignore
    return mappedObservation;
};

export const mapObservationFromDatesStrings = (observation: Observation): Observation => {
    const editDate = new Date(observation.editDate);
    const createDate = new Date(observation.createDate);
    const mappedObservation = {
        ...observation,
        createDate,
        editDate,
    };

    // @ts-ignore
    return mappedObservation;
};
