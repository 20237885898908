import { toastr } from 'react-redux-toastr';
import * as fitnessDataGateways from 'gateways/fitnessDataGateway';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as fitnessDataActions from './fitnessData.actions';
import fitnessDataActionTypes from './fitnessData.actionTypes';
import { FitnessDataMetadata, TeamEventFitnessRecord } from './FitnessData';
import { eventsFitnessRecordsListSelector } from './fitnessData.selector';

function* createFitnessDataSaga({
    fitnessData,
    onFail,
    onSuccess,
}: ReturnType<typeof fitnessDataActions.eventFitnessRecordsCreate>) {
    try {
        yield call(fitnessDataGateways.uploadFitnessData, fitnessData);
        yield put(
            fitnessDataActions.eventFitnessRecordsRequest({
                organizationId: fitnessData.organizationId,
                eventId: fitnessData.eventId as string,
            }),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        console.error(error);

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* deleteFitnessDataSaga({
    organizationId,
    fitnessDataId,
    onFail,
    onSuccess,
}: ReturnType<typeof fitnessDataActions.eventFitnessRecordsDeleteRequest>) {
    try {
        yield call(fitnessDataGateways.deleteFitnessData, {
            organizationId,
            fitnessDataId,
        });
        yield put(fitnessDataActions.removeEventFitnessRecords({ fitnessDataId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        console.error(error);

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* removePlayerFitnessRecordSaga({
    organizationId,
    playerFitnessRecordId,
    playerId,
    onFail,
    onSuccess,
}: ReturnType<typeof fitnessDataActions.removePlayerFitnessRecord>) {
    try {
        /*
         * For external player fitness record we have an event fitness record with a single player.
         * This function should such event fitness record and remove it.
         */
        const eventsFitnessRecords: TeamEventFitnessRecord[] = yield select(
            eventsFitnessRecordsListSelector,
        );
        const targetRecord = eventsFitnessRecords.find((record) => {
            return record.players[playerId]?.id === playerFitnessRecordId;
        });

        const hasSinglePlayer = Object.keys(targetRecord?.players || {}).length === 1;

        if (targetRecord && hasSinglePlayer) {
            yield put(
                fitnessDataActions.eventFitnessRecordsDeleteRequest({
                    organizationId,
                    fitnessDataId: targetRecord.id,
                    onFail,
                    onSuccess,
                }),
            );
        }
    } catch (error) {
        console.error(error);
    }
}

function* fetchFitnessDataSaga({
    organizationId,
    eventId,
}: ReturnType<typeof fitnessDataActions.eventFitnessRecordsRequest>) {
    try {
        const fitnessData: TeamEventFitnessRecord = yield call(
            fitnessDataGateways.fetchFitnessDataByEventId,
            {
                organizationId,
                eventId,
            },
        );
        if (fitnessData) {
            yield put(fitnessDataActions.eventFitnessRecordsRecieved(fitnessData));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* updateFitnessDataSaga({
    organizationId,
    fitnessData,
    onFail,
    onSuccess,
}: ReturnType<typeof fitnessDataActions.eventFitnessRecordsUpdate>) {
    try {
        yield call(fitnessDataGateways.updateFitnessData, {
            organizationId,
            fitnessData,
        });

        yield put(
            fitnessDataActions.eventFitnessRecordsRequest({
                organizationId,
                eventId: fitnessData.eventId as string,
            }),
        );

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (error) {
        console.error(error);

        if (onFail) {
            yield call(onFail);
        }
    }
}

function* fetchPlayerFitnessRecordsListSaga({
    playerId,
    organizationId,
    teamId,
    startDate,
    endDate,
}: ReturnType<typeof fitnessDataActions.playerFitnessRecordsListRequest>) {
    try {
        const fitnessRecordsList: TeamEventFitnessRecord[] = yield call(
            fitnessDataGateways.fetchPlayerFitnessRecordsList,
            {
                playerId,
                teamId,
                organizationId,
                startDate,
                endDate,
            },
        );
        if (fitnessRecordsList) {
            yield put(fitnessDataActions.fitnessRecordsListRecieved(fitnessRecordsList));
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchTeamFitnessRecordsListSaga({
    organizationId,
    teamId,
    startDate,
    endDate,
}: ReturnType<typeof fitnessDataActions.playerFitnessRecordsListRequest>) {
    try {
        const fitnessRecordsList: TeamEventFitnessRecord[] = yield call(
            fitnessDataGateways.fetchTeamFitnessRecordsList,
            {
                teamId,
                organizationId,
                startDate,
                endDate,
            },
        );
        if (fitnessRecordsList) {
            yield put(fitnessDataActions.fitnessRecordsListRecieved(fitnessRecordsList));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* fetchOrganizationFitnessDataMetadataSaga({
    organizationId,
}: ReturnType<typeof fitnessDataActions.fitnessDataMetadataRequest>) {
    try {
        const orgFitnessDataMetadata: FitnessDataMetadata = yield call(
            fitnessDataGateways.fetchOrganizationFitnessDataMetadata,
            organizationId,
        );

        if (orgFitnessDataMetadata) {
            yield put(
                fitnessDataActions.fitnessDataMetadataRecieved({
                    orgFitnessDataMetadata,
                    organizationId,
                }),
            );
        }
    } catch (e: any) {
        yield call(toastr.error, 'Failed to fetch info', '');
    }
}

export function* updateOrganizationFitnessDataMetadataSaga({
    organizationId,
    orgFitnessDataMetadata,
}: ReturnType<typeof fitnessDataActions.fitnessDataMetadataUpdate>) {
    try {
        yield call(fitnessDataGateways.updateOrganizationFitnessDataMetadata, {
            organizationId,
            orgFitnessDataMetadata,
        });
        yield put(fitnessDataActions.fitnessDataMetadataRequest(organizationId));
        yield call(toastr.success, 'Success Update', '');
    } catch (e: any) {
        yield call(toastr.error, 'Failed to update org fitness data metadata', '');
        console.error(e);
    }
}

export default function* fitnessDataSagas() {
    yield all([
        takeEvery(fitnessDataActionTypes.ADD_EVENT_FITNESS_RECORDS, createFitnessDataSaga),
        takeLatest(fitnessDataActionTypes.EVENT_FITNESS_RECORDS_UPDATE, updateFitnessDataSaga),
        takeEvery(fitnessDataActionTypes.EVENT_FITNESS_RECORDS_REQUEST, fetchFitnessDataSaga),
        takeLatest(
            fitnessDataActionTypes.EVENT_FITNESS_RECORDS_DELETE_REQUEST,
            deleteFitnessDataSaga,
        ),
        takeLatest(
            fitnessDataActionTypes.REMOVE_PLAYER_FITNESS_RECORD,
            removePlayerFitnessRecordSaga,
        ),
        takeLatest(
            fitnessDataActionTypes.PLAYER_FITNESS_RECORDS_LIST_REQUEST,
            fetchPlayerFitnessRecordsListSaga,
        ),
        takeEvery(
            fitnessDataActionTypes.TEAM_FITNESS_RECORDS_LIST_REQUEST,
            fetchTeamFitnessRecordsListSaga,
        ),
        takeEvery(
            fitnessDataActionTypes.ORG_FITNESS_DATA_METADATA_REQUEST,
            fetchOrganizationFitnessDataMetadataSaga,
        ),
        takeLatest(
            fitnessDataActionTypes.ORG_FITNESS_DATA_METADATA_UPDATE,
            updateOrganizationFitnessDataMetadataSaga,
        ),
    ]);
}
