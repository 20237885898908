import { MuiTelInput } from 'mui-tel-input';
import { styled } from '@mui/material/styles';

const TelInput = styled(MuiTelInput)(({ theme }) => ({
    [`& .MuiInputLabel-root`]: {
        marginLeft: 0,
    },
    [`& .MuiInputBase-root`]: {
        paddingLeft: theme.spacing(1),
    },
    [`& .MuiIconButton-root`]: {
        padding: 0,
    },
    [`& .MuiTelInput-Flag`]: {
        borderRadius: theme.shape.borderRadius / 2,
        overflow: 'hidden',
    },
    [`& .MuiInputBase-input`]: {
        padding: theme.spacing(0.5, 0.5, 0.5, 0),
    },
    [`& fieldset`]: {
        backgroundColor: theme.custom.background.light,
        border: '2px solid transparent',
    },
}));

export default TelInput;
