import React from 'react';
import { styled, SxProps } from '@mui/material/styles';
import { Typography } from 'common/components';

const StyledPlaceholderBox = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'transparent',
})<{ transparent?: boolean }>(({ theme, transparent }) => ({
    backgroundColor: transparent ? 'transparent' : theme.custom.background.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    opacity: 0.7,
}));

type PlaceholderBoxProps = {
    children: React.ReactElement | string | any[] | null;
    align?: any;
    sx?: SxProps;
    transparent?: boolean;
};

const PlaceholderBox: React.FC<PlaceholderBoxProps> = ({ children, ...rest }) => {
    return (
        <StyledPlaceholderBox align="center" variant="body2" {...rest}>
            {children}
        </StyledPlaceholderBox>
    );
};

export default PlaceholderBox;
