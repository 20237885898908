import { httpsCallable } from 'firebase/functions';
import { functions } from 'common/firebase';
import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import AccessRecord, { AccessTarget, AccessTypes } from 'entities/access/Access';
import { db } from 'common/firebase';
import { handleDocumentSnapshot, handleCollectionSnapshot } from './utils';

const pathBuilders = {
    [AccessTypes.methodology]: ({ methodologyId }: AccessTarget) =>
        `methodologies/${methodologyId}/users_access`,
    [AccessTypes.team]: ({ organizationId, teamId }: AccessTarget) =>
        `organizations/${organizationId}/teams/${teamId}/users_access`,
    [AccessTypes.organization]: ({ organizationId }: AccessTarget) =>
        `organizations/${organizationId}/users_access`,
    [AccessTypes.player]: ({ playerId }: AccessTarget) => `players/${playerId}/users_access`,
    [AccessTypes.longlist]: ({ listId, organizationId }: AccessTarget) =>
        `organizations/${organizationId}/lists/${listId}/users_access`,
    [AccessTypes.shadowTeam]: ({ listId, organizationId }: AccessTarget) =>
        `organizations/${organizationId}/lists/${listId}/users_access`,
};

export const buildBasePath = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => pathBuilders[accessType](target);

export const fetchAccessList = ({
    accessType,
    target,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
}) => {
    const path = buildBasePath({ accessType, target });
    const accessesRef = collection(db, path);

    return getDocs(accessesRef).then(handleCollectionSnapshot);
};

export const fetchAccessData = ({
    accessType,
    target,
    userId,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
    userId: string;
}) => {
    const basePath = buildBasePath({ accessType, target });
    const accessRef = doc(db, `${basePath}/${userId}`);

    return getDoc(accessRef).then(handleDocumentSnapshot);
};

export const updateAccess = (accessRecordData: AccessRecord) => {
    const basePath = buildBasePath(accessRecordData);
    const accessRef = doc(db, `${basePath}/${accessRecordData.userId}`);
    return updateDoc(accessRef, accessRecordData);
};

export const deleteAccess = (accessRecordData: AccessRecord) => {
    const basePath = buildBasePath(accessRecordData);
    const accessRef = doc(db, `${basePath}/${accessRecordData.userId}`);

    return deleteDoc(accessRef);
};

const getUserAccessRecordsFunction = httpsCallable(functions, 'getUserAccessRecordsV2');
export const fetchUserAccessRecords = () =>
    getUserAccessRecordsFunction().then((response) => response.data as AccessRecord[]);

const getDefaultPermissionsFunction = httpsCallable(functions, 'getDefaultPermissionsV2');
export const fetchDefaultPermissions = () =>
    getDefaultPermissionsFunction().then(
        (response) => response.data as Record<string, Record<string, string>>,
    );

export const updateUserRole = httpsCallable(functions, 'updateUserRoleV2');
export const deleteOwnAccessRecord = httpsCallable(functions, 'deleteOwnAccessRecordV2');
