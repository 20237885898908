import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import Player from 'entities/players/Player';
import { ListHeader } from 'common/players-list/ListHeader';
import { PlayerStats } from 'entities/players/players.utils';
import PlaceholderBox from 'common/components/PlaceholderBox';
import { ColumnTitle } from 'common/players-list/ColumnTitle';
import { Box, List, Tooltip, Typography } from 'common/components';
import { PlayersSortIcon } from 'common/players-list/PlayersSortIcon';
import PlayerStatsRow from './PlayerStatsRow';
import { createColorFunc, getHeaderItems } from './playersList.utils';

const Root = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0, 'auto'),
    maxWidth: '100%',
    flexGrow: 1,
    overflowX: 'auto',
}));

type Sorting = {
    attribute: string;
    asc: boolean;
};

type ListItem = {
    player: Player;
    stats?: PlayerStats;
};

const getRecordsSortFunction = (key: string) => {
    return (a: ListItem, b: ListItem) => {
        if (!a.stats) {
            return 1;
        }

        if (!b.stats) {
            return -1;
        }

        // @ts-ignore
        return a.stats[key] > b.stats[key] ? 1 : -1;
    };
};

export type PlayersStatsListProps = {
    playersList: Player[];
    playersStats: PlayerStats[];
};

const PlayersStatsTable: React.FC<PlayersStatsListProps> = ({ playersList, playersStats }) => {
    const { t } = useTranslation();
    const [sorting, setSorting] = useState<Sorting>({ attribute: 'attendance', asc: false });

    const getColorFunc = useMemo(() => {
        return createColorFunc(playersStats);
    }, [playersStats]);

    const playersStatsMap = useMemo(() => {
        const result = new Map<string, PlayerStats>();

        playersStats.forEach((playerStats) => {
            result.set(playerStats.playerId, playerStats);
        });

        return result;
    }, [playersStats.length]);

    const sortedPlayersList = useMemo(() => {
        const records = playersList.map((player) => {
            return {
                player,
                stats: playersStatsMap.get(player.id),
            };
        });
        const sortedRecords = [...records];
        const sortFunc = getRecordsSortFunction(sorting.attribute);
        sortedRecords.sort(sortFunc);

        return sorting.asc ? sortedRecords : sortedRecords.reverse();
    }, [sorting, playersList.length, playersStatsMap]);

    const toggleSorting = useCallback(
        (sortAttr: string) => {
            if (sorting.attribute !== sortAttr) {
                setSorting({ attribute: sortAttr, asc: false });
            } else {
                setSorting({ attribute: sortAttr, asc: !sorting.asc });
            }
        },
        [sorting],
    );

    return (
        <Root>
            <ListHeader sx={{ alignItems: 'flex-end' }}>
                <ColumnTitle
                    key="count"
                    disabled
                    sx={{
                        width: 32,
                        mr: 0.5,
                        ml: 0.5,
                        borderRadius: 1,
                        justifyContent: 'center',
                        backgroundColor: (theme) => theme.custom.background.light,
                    }}
                >
                    {playersList.length}
                </ColumnTitle>
                {getHeaderItems(t).map((headerItem) => (
                    <Tooltip title={headerItem.tooltip} placement="top" key={headerItem.key}>
                        <ColumnTitle
                            sx={{
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                width: headerItem.width,
                                height: 64,
                            }}
                            disabled={!headerItem.hasSorting}
                            isActive={sorting.attribute === headerItem.key}
                            onClick={() => toggleSorting(headerItem.key)}
                        >
                            <Typography variant="body2" sx={{ fontSize: 10 }}>
                                {headerItem.title}
                            </Typography>
                            {headerItem.hasSorting && (
                                <PlayersSortIcon
                                    asc={sorting.attribute === headerItem.key && sorting.asc}
                                    sx={{ mr: 0.5, width: 16 }}
                                />
                            )}
                        </ColumnTitle>
                    </Tooltip>
                ))}
            </ListHeader>
            {sortedPlayersList.length === 0 && (
                <PlaceholderBox sx={{ mt: 2 }}>
                    {t('scouting.players.list.noPlayersPlaceholder') as string}
                </PlaceholderBox>
            )}
            <List sx={{ overflowY: 'auto', width: 'fit-content', px: 1, py: 0, flexGrow: 1 }}>
                {sortedPlayersList.map((record) => (
                    <PlayerStatsRow
                        key={record.player.id}
                        player={record.player}
                        stats={record.stats}
                        getColorFunc={getColorFunc}
                    />
                ))}
            </List>
        </Root>
    );
};

export default PlayersStatsTable;
