export default {
    REQUEST_ACCESS_LIST: 'ENTITIES/ACCESS/REQUEST_ACCESS_LIST',
    ACCESS_LIST_RECEIVED: 'ENTITIES/ACCESS/ACCESS_LIST_RECEIVED',
    ACCESS_DATA_RECEIVED: 'ENTITIES/ACCESS/ACCESS_DATA_RECEIVED',
    DELETE_ACCESS: 'ENTITIES/ACCESS/DELETE_ACCESS',
    DELETE_OWN_ACCESS: 'ENTITIES/ACCESS/DELETE_OWN_ACCESS',
    UPDATE_ACCESS: 'ENTITIES/ACCESS/UPDATE_ACCESS',
    REMOVE_ACCESS_FROM_LIST: 'ENTITIES/ACCESS/REMOVE_ACCESS_FROM_LIST',
    REQUEST_ACCESS_DATA: 'ENTITIES/ACCESS/REQUEST_ACCESS_DATA',
    REQUEST_USER_ACCESS_RECORDS: 'ENTITIES/ACCESS/REQUEST_USER_ACCESS_RECORDS',
    REQUEST_DEFAULT_PERMISSIONS: 'ENTITIES/ACCESS/REQUEST_DEFAULT_PERMISSIONS',
    DEFAULT_PERMISSIONS_RECEIVED: 'ENTITIES/ACCESS/DEFAULT_PERMISSIONS_RECEIVED',
    UPDATE_USER_ROLE: 'ENTITIES/ACCESS/UPDATE_USER_ROLE',
};
