import { db, functions } from 'common/firebase';
import { httpsCallable } from 'firebase/functions';
import { Injury, InjuryData } from 'entities/injuries/Injury';
import { mapInjuryFromDatesString } from 'entities/injuries/injuries.utils';
import {
    addDoc,
    collection,
    collectionGroup,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
} from 'firebase/firestore';
import { handleCollectionSnapshot, handleDocumentSnapshot } from './utils';

export const getInjuriesCollectionPath = (playerId: string) => {
    return `players/${playerId}/injuries`;
};

export const getInjuriesCollectionRef = (playerId: string) => {
    const collectionPath = getInjuriesCollectionPath(playerId);

    return collection(db, collectionPath);
};

export const getInjuryDocPath = (playerId: string, injuryId: string) => {
    return `players/${playerId}/injuries/${injuryId}`;
};

export const getInjuryDocRef = (playerId: string, injuryId: string) => {
    const docPath = getInjuryDocPath(playerId, injuryId);

    return doc(db, docPath);
};

export const fetchPlayerInjuries = (playerId: string) => {
    const playerInjuriesRef = getInjuriesCollectionRef(playerId);

    return getDocs(playerInjuriesRef).then(handleCollectionSnapshot);
};

export const fetchPlayersInjuries = (playersIds: string[]) => {
    const playerInjuriesRef = collectionGroup(db, 'injuries');
    const q = query(playerInjuriesRef, where('playerId', 'in', playersIds));

    return getDocs(q).then(handleCollectionSnapshot);
};

export const fetchInjury = ({ playerId, injuryId }: { playerId: string; injuryId: string }) => {
    const injuryRef = getInjuryDocRef(playerId, injuryId);

    return getDoc(injuryRef).then(handleDocumentSnapshot);
};

export const createInjury = ({
    playerId,
    injuryData,
}: {
    playerId: string;
    injuryData: InjuryData;
}) => {
    const injuriesCollection = getInjuriesCollectionRef(playerId);

    return addDoc(injuriesCollection, injuryData).then((doc) => doc.id);
};

export const updateInjury = ({
    playerId,
    injuryId,
    injuryData,
}: {
    playerId: string;
    injuryId: string;
    injuryData: InjuryData;
}) => {
    const injuryDoc = getInjuryDocRef(playerId, injuryId);

    return updateDoc(injuryDoc, injuryData);
};

export const deleteInjury = ({ playerId, injuryId }: { playerId: string; injuryId: string }) => {
    const injuryDoc = getInjuryDocRef(playerId, injuryId);

    return deleteDoc(injuryDoc);
};

const fetchTeamInjuriesFunction = httpsCallable(functions, 'fetchTeamInjuriesV2');
export const fetchTeamInjuries = ({
    teamId,
    organizationId,
}: {
    teamId: string;
    organizationId: string;
}) => {
    return fetchTeamInjuriesFunction({ teamId, organizationId }).then((res) => {
        const result = res.data as Injury[];

        return result.map(mapInjuryFromDatesString);
    });
};
