import * as injuriesGateway from 'gateways/injuriesGateway';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { Injury } from './Injury';
import actionTypes from './injuries.actionTypes';
import * as injuriesActions from './injuries.actions';

export function* fetchPlayerInjuriesSaga({
    playerId,
}: ReturnType<typeof injuriesActions.playerInjuriesRequest>) {
    try {
        const injuries: Injury[] = yield call(injuriesGateway.fetchPlayerInjuries, playerId);

        if (injuries.length > 0) {
            yield put(injuriesActions.injuriesListReceived({ injuries }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchInjurySaga({
    playerId,
    injuryId,
}: ReturnType<typeof injuriesActions.requestInjury>) {
    try {
        const injury: Injury = yield call(injuriesGateway.fetchInjury, { playerId, injuryId });

        if (injury) {
            yield put(injuriesActions.injuriesListReceived({ injuries: [injury] }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* fetchTeamPlayersInjuriesSaga({
    teamId,
    organizationId,
}: ReturnType<typeof injuriesActions.teamPlayersInjuriesRequest>) {
    try {
        const injuries: Injury[] = yield call(injuriesGateway.fetchTeamInjuries, {
            teamId,
            organizationId,
        });

        if (injuries) {
            yield put(injuriesActions.injuriesListReceived({ injuries }));
        }
    } catch (e) {
        console.error(e);
    }
}

export function* updateInjurySaga({
    playerId,
    injuryId,
    injuryData,
    onSuccess,
    onFail,
}: ReturnType<typeof injuriesActions.updateInjury>) {
    try {
        yield call(injuriesGateway.updateInjury, {
            playerId,
            injuryId,
            injuryData,
        });

        yield put(injuriesActions.requestInjury({ playerId, injuryId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }
        console.error(e);
    }
}

export function* createInjurySaga({
    playerId,
    injuryData,
    onSuccess,
    onFail,
}: ReturnType<typeof injuriesActions.updateInjury>) {
    try {
        const injuryId: string = yield call(injuriesGateway.createInjury, {
            playerId,
            injuryData,
        });
        yield put(injuriesActions.requestInjury({ playerId, injuryId }));

        if (onSuccess) {
            yield call(onSuccess);
        }

        return injuryId;
    } catch (e) {
        if (onFail) {
            yield call(onFail);
        }

        console.error(e);
    }
}

export function* deleteInjurySaga({
    playerId,
    injuryId,
    onSuccess,
}: ReturnType<typeof injuriesActions.deleteInjury>) {
    try {
        yield call([injuriesGateway, injuriesGateway.deleteInjury], {
            playerId,
            injuryId,
        });
        yield put(injuriesActions.removeInjury({ injuryId }));

        if (onSuccess) {
            yield call(onSuccess);
        }
    } catch (e) {
        console.error(e);
    }
}

function* playersSagas() {
    yield all([
        takeEvery(actionTypes.TEAM_PLAYERS_INJURIES_REQUEST, fetchTeamPlayersInjuriesSaga),
        takeEvery(actionTypes.PLAYER_INJURIES_REQUEST, fetchPlayerInjuriesSaga),
        takeLatest(actionTypes.REQUEST_INJURY, fetchInjurySaga),
        takeLatest(actionTypes.CREATE_INJURY, createInjurySaga),
        takeLatest(actionTypes.UPDATE_INJURY, updateInjurySaga),
        takeLatest(actionTypes.DELETE_INJURY, deleteInjurySaga),
    ]);
}

export default playersSagas;
