export default {
    TEAM_PLAYERS_INJURIES_REQUEST: 'ENTITIES/INJURIES/TEAM_PLAYERS_INJURIES_REQUEST',
    PLAYER_INJURIES_REQUEST: 'ENTITIES/INJURIES/PLAYER_INJURIES_REQUEST',
    INJURIES_LIST_RECEIVED: 'ENTITIES/INJURIES/INJURIES_LIST_RECEIVED',
    INJURY_RECEIVED: 'ENTITIES/INJURIES/INJURY_RECEIVED',
    DELETE_INJURY: 'ENTITIES/INJURIES/DELETE_INJURY',
    REMOVE_INJURY: 'ENTITIES/INJURIES/REMOVE_INJURY', // locally from redux store
    UPDATE_INJURY: 'ENTITIES/INJURIES/UPDATE_INJURY',
    CREATE_INJURY: 'ENTITIES/INJURIES/CREATE_INJURY',
    REQUEST_INJURY: 'ENTITIES/INJURIES/REQUEST_INJURY',
};
