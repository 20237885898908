import { isBefore } from 'date-fns';
import { EventPlayerInfo } from 'entities/events/Event';
import { TimePerDrillType } from 'entities/drills/drills.utils';
import { replaceStrangeSymbols } from 'common/utils/stringsUtils';
import Player, { Foot, PlayerData, PlayerStatus } from 'entities/players/Player';
import { Positions, sortedPositions } from 'common/components/pitch/positionsCoordinates';
import { mapInjuryFromDatesString } from 'entities/injuries/injuries.utils';

export const nameSortFunc = (a: EventPlayerInfo, b: EventPlayerInfo) => {
    const aName = a.lastName || a.enLastName || '';
    const bName = b.lastName || b.enLastName || '';

    return aName.localeCompare(bName);
};

export const heightSortFunc = (a: Player, b: Player) => {
    if (!a.height) {
        return 1;
    }

    if (!b.height) {
        return -1;
    }

    return a.height > b.height ? 1 : -1;
};

export const weightSortFunc = (a: Player, b: Player) => {
    if (!a.weight) {
        return 1;
    }

    if (!b.weight) {
        return -1;
    }

    return a.weight > b.weight ? 1 : -1;
};

export const qualitySortFunc = (a: Player, b: Player) => {
    if (!a.quality) {
        return 1;
    }

    if (!b.quality) {
        return -1;
    }

    return a.quality < b.quality ? 1 : -1;
};

export const potentialSortFunc = (a: Player, b: Player) => {
    if (!a.potential) {
        return 1;
    }

    if (!b.potential) {
        return -1;
    }

    return a.potential < b.potential ? 1 : -1;
};

export const ageSortFunc = (a: Player, b: Player) => {
    if (!a.dateOfBirth) {
        return 1;
    }

    if (!b.dateOfBirth) {
        return -1;
    }

    return isBefore(a.dateOfBirth, b.dateOfBirth) ? 1 : -1;
};

export const createDateSortFunc = (a: Player, b: Player) => {
    if (!a.createDate) {
        return 1;
    }

    if (!b.createDate) {
        return -1;
    }

    return isBefore(a.createDate, b.createDate) ? 1 : -1;
};

export const getPrimePosition = (player: EventPlayerInfo) => {
    const primePositions = Object.entries(player.positions)
        .filter((entry) => entry[1] === 1)
        .map((entry) => entry[0]);
    primePositions.sort();
    const primePosition = primePositions[0];

    if (primePosition) {
        return primePosition as Positions;
    }

    const secondaryPositions = Object.entries(player.positions)
        .filter((entry) => entry[1] === 2)
        .map((entry) => entry[0]);
    secondaryPositions.sort();
    const secondaryPosition = secondaryPositions[0];

    return (secondaryPosition as Positions) || null;
};

export const positionSortFunc = (a: EventPlayerInfo, b: EventPlayerInfo) => {
    const aPrimePosition = getPrimePosition(a);
    const bPrimePosition = getPrimePosition(b);
    /* 100 - is a big number, lrager then number of positions */
    const aIndex = aPrimePosition ? sortedPositions.indexOf(aPrimePosition) : 100;
    const bIndex = bPrimePosition ? sortedPositions.indexOf(bPrimePosition) : 100;

    if (aIndex === bIndex) {
        const aFullName = `${a.lastName} ${a.firstName}`;
        const bFullName = `${b.lastName} ${b.firstName}`;

        return aFullName.localeCompare(bFullName);
    }

    return aIndex < bIndex ? -1 : 1;
};

export const getPlayersSortFunc = (filterName: string) => {
    if (filterName === 'name') {
        return nameSortFunc;
    }

    if (filterName === 'position') {
        return positionSortFunc;
    }

    if (filterName === 'height') {
        return heightSortFunc;
    }

    if (filterName === 'weight') {
        return weightSortFunc;
    }

    if (filterName === 'age') {
        return ageSortFunc;
    }

    if (filterName === 'quality') {
        return qualitySortFunc;
    }

    if (filterName === 'potential') {
        return potentialSortFunc;
    }

    if (filterName === 'createDate') {
        return createDateSortFunc;
    }

    return () => 1;
};

export const createEmptyPlayer = (organizationId: string, teamId: string) => {
    const newPlayer: PlayerData = {
        firstName: '',
        lastName: '',
        middleName: '',
        enFirstName: '',
        enLastName: '',
        enMiddleName: '',
        weight: null,
        height: null,
        playerNumber: '',
        foot: Foot.right,
        dateOfBirth: new Date(),
        positions: {},
        filePath: null,
        countries: [],
        email: null,
        tel: null,
        userId: null,
        injuries: [],
        profile: [],
        createDate: new Date(),
        status: PlayerStatus.active,
        organizations: {
            [organizationId]: {
                active: true,
            },
        },
        teams: {
            [teamId]: {
                active: true,
                organizationId,
            },
        },
        meta: {},
        agent: null,
        contract: {
            salary: null,
            expirationDate: null,
            transferFee: null,
            notes: '',
        },
        grade: null,
        quality: null,
        potential: null,
        club: null,
        references: {},
        prior: false,
    };

    return newPlayer;
};

export const mapPlayerToDatesStrings = (player: PlayerData | Player) => {
    const dateOfBirth = player.dateOfBirth.toISOString();
    const createDate = player.createDate.toISOString();
    const linkUserInvitationDate = player.meta?.linkUserInvitationDate
        ? player.meta.linkUserInvitationDate.toISOString()
        : null;
    const linkUserInvitationAcceptedDate = player.meta?.linkUserInvitationAcceptedDate
        ? player.meta.linkUserInvitationAcceptedDate.toISOString()
        : null;
    const linkUserInvitationAccepted = player.meta?.linkUserInvitationAccepted || null;

    const injuries = player.injuries.map(({ startDate, endDate, createdDate, ...rest }) => ({
        ...rest,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        createdDate: createdDate ? createdDate.toISOString() : startDate.toISOString(),
    }));
    const club = player.club
        ? { ...player.club, updatedDate: player.club.updatedDate.toISOString() }
        : player.club;

    return {
        ...player,
        dateOfBirth,
        createDate,
        injuries,
        club,
        meta: {
            linkUserInvitationDate,
            linkUserInvitationAcceptedDate,
            linkUserInvitationAccepted,
        },
    };
};

export const mapPlayerFromDatesStrings = (player: Player) => {
    const dateOfBirth = new Date(player.dateOfBirth);
    const createDate = new Date(player.createDate);
    const linkUserInvitationDate = player.meta?.linkUserInvitationDate
        ? new Date(player.meta.linkUserInvitationDate)
        : null;
    const linkUserInvitationAcceptedDate = player.meta?.linkUserInvitationAcceptedDate
        ? new Date(player.meta.linkUserInvitationAcceptedDate)
        : null;
    const linkUserInvitationAccepted = player.meta?.linkUserInvitationAccepted || null;

    const injuries = player.injuries.map(mapInjuryFromDatesString);
    const club = player.club
        ? { ...player.club, updatedDate: new Date(player.club.updatedDate) }
        : player.club;

    return {
        ...player,
        dateOfBirth,
        createDate,
        injuries,
        club,
        meta: {
            linkUserInvitationDate,
            linkUserInvitationAcceptedDate,
            linkUserInvitationAccepted,
        },
    };
};

export type PlayerStats = {
    playerId: string;
    attendance: number | null;
    totalSessionsTime: number;
    sessionsPresent: number;
    sessionsSkiped: number;
    individualSessionsCount: number;
    timePerDrillType: TimePerDrillType;
    goals: number;
    assists: number;
    redCards: number;
    yellowCards: number;
    newGoals: number;
    activeGoals: number;
    gamesTime: number;
    subIn: number;
    lineup: number;
    avgLoad: number | null;
    avgWellness: number | null;
    avgEvaluation: number | null;
    newInjuries: number;
    healedInjuries: number;
    activeInjuries: number;
};

export const sanityzeString = (text: string) => {
    return replaceStrangeSymbols(text)
        .replace(/[^a-zA-Z0-9]/g, '')
        .replace(/\s/g, '')
        .toLowerCase();
};

export const getPlayerHashName = (player: Player) => {
    const { firstName, lastName, enFirstName, enLastName } = player;
    const fullName = [firstName || '', lastName || '', enFirstName || '', enLastName || ''].join(
        '',
    );

    return sanityzeString(fullName);
};
