import { createSelector } from 'reselect';
import { RootState } from 'store';
import { Injury } from './Injury';

export const playersInjuriesHashSelector = (state: RootState) =>
    state.entities.injuries.injuriesHash;

export const playersInjuriesListSelector = createSelector(
    playersInjuriesHashSelector,
    (playersInjuriesHash) => Object.values(playersInjuriesHash) as Injury[],
);

export const playerInjuriesSelector = createSelector(
    playersInjuriesListSelector,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (playersInjuriesList, playerId) =>
        playersInjuriesList.filter((injury) => injury.playerId === playerId),
);

export const playersInjuriesSelector = createSelector(
    playersInjuriesListSelector,
    (_: any, { playersIds }: { playersIds: string[] }) => playersIds,
    (playersInjuriesList, playersIds) =>
        playersInjuriesList.filter((injury) => playersIds.includes(injury.playerId)),
);
