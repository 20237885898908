import _omit from 'lodash/omit';
import _keyBy from 'lodash/keyBy';
import { Injury } from './Injury';
import actionTypes from './injuries.actionTypes';

type PlayersState = {
    injuriesHash: Record<string, Injury>;
};
const initialState = {
    injuriesHash: {},
};

const player = (state: PlayersState = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.INJURY_RECEIVED:
            return {
                ...state,
                injuriesHash: {
                    ...state.injuriesHash,
                    [action.injury.id]: action.injury,
                },
            };
        case actionTypes.INJURIES_LIST_RECEIVED:
            return {
                ...state,
                injuriesHash: {
                    ...state.injuriesHash,
                    ..._keyBy(action.injuries, 'id'),
                },
            };
        case actionTypes.REMOVE_INJURY:
            return {
                ...state,
                injuriesHash: { ..._omit(state.injuriesHash, action.injuryId) },
            };
        default:
            return state;
    }
};

export default player;
