import AccessRecord, { AccessTarget, AccessTypes, Roles } from './Access';
import accessActionTypes from './access.actionTypes';

export const requestAccessList = ({
    target,
    accessType,
}: {
    target: AccessTarget;
    accessType: AccessTypes;
}) => ({
    type: accessActionTypes.REQUEST_ACCESS_LIST,
    target,
    accessType,
});

export const requestAccessDataAction = ({
    accessType,
    target,
    userId,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
    userId: string;
}) => ({
    type: accessActionTypes.REQUEST_ACCESS_DATA,
    accessType,
    target,
    userId,
});

export const requestUserAccessRecords = () => ({
    type: accessActionTypes.REQUEST_USER_ACCESS_RECORDS,
});

export const accessListReceived = ({ accessList }: { accessList: AccessRecord[] }) => ({
    type: accessActionTypes.ACCESS_LIST_RECEIVED,
    accessList,
});

export const accessDataReceived = ({ accessRecord }: { accessRecord: AccessRecord }) => ({
    type: accessActionTypes.ACCESS_DATA_RECEIVED,
    accessRecord,
});

export const updateAccessRecord = ({
    accessRecord,
    onSuccess,
    onFail,
}: {
    accessRecord: Omit<AccessRecord, 'gid'>;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: accessActionTypes.UPDATE_ACCESS,
    accessRecord,
    onSuccess,
    onFail,
});

export const deleteAccess = ({ gid }: { gid: string }) => ({
    type: accessActionTypes.DELETE_ACCESS,
    gid,
});

export const deleteOwnAccess = ({
    gid,
    onSuccess,
    onFail,
}: {
    gid: string;
    onSuccess: VoidFunction;
    onFail: (errorCode: string) => void;
}) => ({
    type: accessActionTypes.DELETE_OWN_ACCESS,
    gid,
    onSuccess,
    onFail,
});

export const removeAccessFromListAction = ({ gid }: { gid: string }) => ({
    type: accessActionTypes.REMOVE_ACCESS_FROM_LIST,
    gid,
});

export const requestDefaultPermissions = () => ({
    type: accessActionTypes.REQUEST_DEFAULT_PERMISSIONS,
});

export const defaultPermissionsReceived = (
    defaultPermissions: Record<string, Record<string, string>>,
) => ({
    type: accessActionTypes.DEFAULT_PERMISSIONS_RECEIVED,
    defaultPermissions,
});

export const updateUserRole = ({
    userId,
    accessType,
    target,
    newRole,
    onSuccess,
    onFail,
}: {
    userId: string;
    accessType: AccessTypes;
    target: AccessTarget;
    newRole: Roles;
    onSuccess: VoidFunction;
    onFail: VoidFunction;
}) => ({
    type: accessActionTypes.UPDATE_USER_ROLE,
    userId,
    accessType,
    target,
    newRole,
    onSuccess,
    onFail,
});
