import { addMinutes } from 'date-fns';
import Event, { EventPlayerInfo } from 'entities/events/Event';
import { getPrimePosition, positionSortFunc } from 'entities/players/players.utils';
import {
    Attendance,
    AttendanceRecord,
    AttendanceStatus,
    SessionData,
    SessionDrill,
} from 'entities/events/Session';
import {
    playersListGroups,
    PlayersListGroups,
    playersListGroupsList,
} from 'common/components/pitch/positionsCoordinates';
import { Injury } from 'entities/injuries/Injury';
import { checkInjuryByDate } from 'entities/injuries/injuries.utils';

export const aggregateDurrationByDrillType = (drills: SessionDrill[]) => {
    const activitiesMap = drills.reduce(
        (acc: { [key: string]: number }, { drillType, duration }) => ({
            ...acc,
            [drillType]: (duration || 0) + (acc[drillType] || 0),
        }),
        {},
    );

    return Object.entries(activitiesMap).map(([key, value]) => ({ name: key, duration: value }));
};

type Day = {
    events: Event[];
};

export const totalEvents = (days: Day[], type: string) => {
    return days
        .reduce((acc: Event[], { events }) => acc.concat(events), [])
        .filter(({ eventType }) => eventType === type).length;
};

const orderMap = {
    [AttendanceStatus.present]: 0,
    [AttendanceStatus.promotion]: 1,
    [AttendanceStatus.reason]: 2,
    [AttendanceStatus.injury]: 3,
    [AttendanceStatus.ill]: 4,
    [AttendanceStatus.skip]: 5,
};

const getOrder = (status: AttendanceStatus) => {
    return orderMap[status] || 0;
};

export const sortPlayersByAttendance = (
    attendanceRecords: AttendanceRecord[],
): AttendanceRecord[] => {
    const players = [...attendanceRecords];
    players.sort((a, b) => {
        const orderA = getOrder(a.status);
        const orderB = getOrder(b.status);

        return orderA - orderB;
    });

    return players;
};

export const getPlayersListGroups = (players: EventPlayerInfo[]) => {
    const coveredPlayersIds: string[] = [];
    const result = playersListGroupsList.map((playersListGroup) => {
        const groupPositions = playersListGroups.get(playersListGroup) || [];
        const groupPlayers = players.filter((player) => {
            const mainPosition = getPrimePosition(player);
            return groupPositions.includes(mainPosition);
        });
        coveredPlayersIds.push(...groupPlayers.map((player) => player.id));
        groupPlayers.sort(positionSortFunc);
        return {
            group: playersListGroup,
            groupPlayersIds: groupPlayers.map((player) => player.id),
        };
    });

    const notCoveredPlayers = players.filter((player) => !coveredPlayersIds.includes(player.id));

    if (notCoveredPlayers.length > 0) {
        notCoveredPlayers.sort(positionSortFunc);
        result.push({
            group: PlayersListGroups.none,
            groupPlayersIds: notCoveredPlayers.map((player) => player.id),
        });
    }

    return result;
};

export const calculateSessionEndTime = (sessionData: SessionData) => {
    const totalMinutes = sessionData.details.drills.reduce((acc, sessionDrill) => {
        return acc + (sessionDrill.duration || 0) * (sessionDrill.sets || 0);
    }, 0);

    return {
        ...sessionData,
        end: addMinutes(sessionData.start, totalMinutes),
    };
};

export const updatePlayersAttendance = ({
    attendance,
    playersIdsToAdd,
    playersIdsToUpdate,
    playersIdsToRemove,
    playersInfoMap,
    teamInjuries,
    sessionDate,
}: {
    attendance: Attendance;
    playersIdsToAdd: string[];
    playersIdsToUpdate: string[];
    playersIdsToRemove: string[];
    playersInfoMap: Map<string, EventPlayerInfo>;
    teamInjuries: Injury[];
    sessionDate: Date;
}) => {
    const newAttendance = { ...attendance };

    playersIdsToRemove.forEach((playerId) => {
        delete newAttendance[playerId];
    });

    playersIdsToAdd.forEach((playerId) => {
        const eventPlayerInfo = playersInfoMap.get(playerId);

        if (!eventPlayerInfo) {
            return;
        }

        const playerInjuries = teamInjuries.filter((injury) => injury.playerId === playerId);

        const hasInjury = checkInjuryByDate(playerInjuries, sessionDate);

        Object.assign(newAttendance, {
            [playerId]: {
                time: 0,
                status: hasInjury ? AttendanceStatus.injury : AttendanceStatus.present,
                player: eventPlayerInfo,
            },
        });
    });

    playersIdsToUpdate.forEach((playerId) => {
        const eventPlayerInfo = playersInfoMap.get(playerId);

        if (!eventPlayerInfo || !newAttendance[playerId]) {
            return;
        }

        Object.assign(newAttendance[playerId], { player: eventPlayerInfo });
    });

    return newAttendance;
};
