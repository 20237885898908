import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    addMonths,
    differenceInMonths,
    endOfDay,
    endOfToday,
    format,
    isAfter,
    isBefore,
    startOfDay,
    subDays,
    subMonths,
} from 'date-fns';
import { styled, SxProps } from '@mui/material';
import { DatesRange } from 'common/CommonTypes';
import { Box, Button, DatePicker, Popover } from 'common/components';

const StyledPopover = styled(Popover)(({ theme }) => ({
    [`& .MuiPaper-root`]: {
        padding: theme.spacing(1),
        boxShadow: theme.custom.boxShadow.main,
    },
}));

const Root = styled(Box)(() => ({
    display: 'flex',
}));

type State = DatesRange & {
    variant: number | 'custom';
};

const getValidEndDate = (newStartDate: Date, currentEndDate: Date) => {
    if (isAfter(newStartDate, currentEndDate)) {
        return endOfDay(newStartDate);
    }

    if (differenceInMonths(currentEndDate, newStartDate) >= 12) {
        return endOfDay(addMonths(newStartDate, 12));
    }

    return currentEndDate;
};

const getValidStartDate = (currentStartDate: Date, newEndDate: Date) => {
    if (isBefore(newEndDate, currentStartDate)) {
        return startOfDay(newEndDate);
    }

    if (differenceInMonths(currentStartDate, newEndDate)) {
        return subMonths(newEndDate, 12);
    }

    return currentStartDate;
};

type PeriodSelectorProps = {
    selectDefault?: boolean;
    options: number[];
    sx?: SxProps;
    onSelect: (fromDate: Date, toDate: Date) => void;
};

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
    selectDefault,
    options,
    onSelect,
    ...rest
}) => {
    const { t } = useTranslation();
    const [state, setState] = useState<State>({
        startDate: startOfDay(subDays(new Date(), options[0])),
        endDate: endOfDay(new Date()),
        variant: options[0],
    });
    const [anchorEl, setAnchorEl] = useState<any>(null);

    useEffect(() => {
        if (selectDefault) {
            onSelect(state.startDate, state.endDate);
        }
    }, []);

    const handleSelect = useCallback(
        (days: number) => {
            const fromDate = startOfDay(subDays(new Date(), days));
            const toDate = endOfToday();
            setState({ startDate: fromDate, endDate: toDate, variant: days });
            onSelect(fromDate, toDate);
        },
        [onSelect],
    );

    const handleCustomPeriod = useCallback(() => {
        const { startDate, endDate } = state;
        onSelect(startOfDay(startDate), endOfDay(endDate));
        setAnchorEl(null);
    }, [onSelect, state]);

    const handleSetStartDate = useCallback(
        (startDate: Date) => {
            const newEndDate = getValidEndDate(startDate, state.endDate);
            setState({ startDate: startDate, endDate: newEndDate, variant: 'custom' });
        },
        [onSelect, state],
    );

    const handleSetEndDate = useCallback(
        (endDate: Date) => {
            const newStartDate = getValidStartDate(state.startDate, endDate);
            setState({ startDate: newStartDate, endDate: endDate, variant: 'custom' });
        },
        [onSelect, state],
    );

    const periodText = `${format(state.startDate, 'dd MMM')} - ${format(state.endDate, 'dd MMM')}`;

    return (
        <Root {...rest}>
            {options.map((days) => (
                <Button
                    size="small"
                    color={state.variant === days ? 'primary' : undefined}
                    variant={state.variant === days ? 'contained' : undefined}
                    onClick={() => handleSelect(days)}
                    sx={{ mr: 1 }}
                >
                    {t('common.periodSelector.daysCount', { days })}
                </Button>
            ))}
            <Button
                size="small"
                color={state.variant === 'custom' ? 'primary' : undefined}
                variant={state.variant === 'custom' ? 'contained' : undefined}
                onClick={(e: any) => setAnchorEl(e.target)}
            >
                {t('organization.teamStats.customPeriod')}
            </Button>
            <StyledPopover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <DatePicker
                        sx={{ mx: 0.5 }}
                        value={state.startDate}
                        label={t('organization.teamStats.fromDate')}
                        format="dd MMM"
                        onChange={(date: Date | null) => handleSetStartDate(date || new Date())}
                    />
                    <DatePicker
                        sx={{ mx: 0.5 }}
                        value={state.endDate}
                        label={t('organization.teamStats.toDate')}
                        format="dd MMM"
                        maxDate={new Date()}
                        onChange={(date: Date | null) => handleSetEndDate(date || new Date())}
                    />
                </Box>
                <Button
                    size="small"
                    sx={{ m: 0.5, width: 'calc(100% - 8px)' }}
                    color={state.variant === 'custom' ? 'primary' : 'inherit'}
                    variant="contained"
                    onClick={handleCustomPeriod}
                >
                    {t('common.periodSelector.showCustomPeriodData', { period: periodText })}
                </Button>
            </StyledPopover>
        </Root>
    );
};

export default PeriodSelector;
