import actionTypes from './injuries.actionTypes';
import { Injury, InjuryData } from './Injury';

export const playerInjuriesRequest = (playerId: string) => ({
    type: actionTypes.PLAYER_INJURIES_REQUEST,
    playerId,
});

export const teamPlayersInjuriesRequest = ({ teamId, organizationId }: {
    teamId: string;
    organizationId: string;
}) => ({
    type: actionTypes.TEAM_PLAYERS_INJURIES_REQUEST,
    teamId,
    organizationId,
});

export const requestInjury = ({ playerId, injuryId }: { playerId: string; injuryId: string }) => ({
    type: actionTypes.REQUEST_INJURY,
    playerId,
    injuryId,
});

export const injuriesListReceived = ({ injuries }: { injuries: Injury[] }) => ({
    type: actionTypes.INJURIES_LIST_RECEIVED,
    injuries,
});

export const injuryReceived = ({ injury }: { injury: Injury }) => ({
    type: actionTypes.INJURY_RECEIVED,
    injury,
});

export const updateInjury = ({
    playerId,
    injuryId,
    injuryData,
    onSuccess,
    onFail,
}: {
    playerId: string;
    injuryId: string;
    injuryData: InjuryData;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.UPDATE_INJURY,
    playerId,
    injuryId,
    injuryData,
    onSuccess,
    onFail,
});

export const createInjury = ({
    playerId,
    injuryData,
    onSuccess,
    onFail,
}: {
    playerId: string;
    injuryData: InjuryData;
    onSuccess?: VoidFunction;
    onFail?: VoidFunction;
}) => ({
    type: actionTypes.CREATE_INJURY,
    playerId,
    injuryData,
    onSuccess,
    onFail,
});

export const deleteInjury = ({
    playerId,
    injuryId,
    onSuccess,
}: {
    playerId: string;
    injuryId: string;
    onSuccess?: VoidFunction;
}) => ({
    type: actionTypes.DELETE_INJURY,
    playerId,
    injuryId,
    onSuccess,
});

export const removeInjury = ({ injuryId }: { injuryId: string }) => ({
    type: actionTypes.REMOVE_INJURY,
    injuryId,
});
