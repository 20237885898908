import { RootState } from 'store';
import { isBefore } from 'date-fns';
import { createSelector } from 'reselect';
import { isBetween } from 'common/utils/dateTimeHelpers';
import { TeamEventFitnessRecord } from './FitnessData';

export const fitnessDataHashSelector = (state: RootState) =>
    state.entities.fitnessData.fitnessDataHash;

export const eventsFitnessRecordsListSelector = createSelector(
    fitnessDataHashSelector,
    (fitnessDataHash) => Object.values(fitnessDataHash) as TeamEventFitnessRecord[],
);

export const fitnessDataMetadataHashSelector = (state: RootState) =>
    state.entities.fitnessData.fitnessDataMetadataHash;

export const fitnessDataMetadataSelector = createSelector(
    fitnessDataMetadataHashSelector,
    (_: any, { organizationId }: { organizationId: string }) => organizationId,
    (fitnessDataMetadataHash, organizationId) => fitnessDataMetadataHash[organizationId],
);

export const fitnessDataByEventIdSelector = createSelector(
    eventsFitnessRecordsListSelector,
    (_: any, { eventId }: { eventId: string }) => eventId,
    (eventsFitnessRecordsList, eventId) => {
        return eventsFitnessRecordsList.find((fitnessData) => fitnessData.eventId === eventId);
    },
);

export const playerFitnessRecordsListSelector = createSelector(
    eventsFitnessRecordsListSelector,
    (_: any, { playerId }: { playerId: string }) => playerId,
    (_: any, { startDate }: { startDate?: Date }) => startDate,
    (_: any, { endDate }: { endDate?: Date }) => endDate,
    (eventsFitnessRecordsList, playerId, startDate, endDate) => {
        if (!endDate || !startDate) return [];

        const result = eventsFitnessRecordsList
            .filter((eventFitnessData) => isBetween(eventFitnessData.date, startDate, endDate))
            .map((eventFitnessData) => {
                const { date, players } = eventFitnessData;
                return (
                    players[playerId] && {
                        ...players[playerId],
                        date,
                    }
                );
            })
            .filter(Boolean);

        result.sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));

        return result;
    },
);

export const teamEventsFitnessRecordsListSelector = createSelector(
    eventsFitnessRecordsListSelector,
    (_: any, { teamId }: { teamId: string }) => teamId,
    (_: any, { startDate }: { startDate?: Date }) => startDate,
    (_: any, { endDate }: { endDate?: Date }) => endDate,
    (eventsFitnessRecordsList, teamId, startDate, endDate) => {
        if (!endDate || !startDate) return [];

        const result = eventsFitnessRecordsList.filter(
            (record) => isBetween(record.date, startDate, endDate) && record.teamId === teamId,
        );

        result.sort((a, b) => (isBefore(a.date, b.date) ? 1 : -1));

        return result;
    },
);
