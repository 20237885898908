import _ from 'lodash';
import Player from 'entities/players/Player';
import { getPlayerGameTime } from './games.utils';
import { EventPlayerInfo, EventTypes } from './Event';
import { getPlayerSessionTime } from './sessions.utils';
import Game, { PlayerStatus } from './Game';
import Session, { AttendanceStatus } from './Session';

export const playerToEventInfo = (player: Player | EventPlayerInfo): EventPlayerInfo => {
    const {
        id,
        lastName,
        firstName,
        enFirstName = '',
        enLastName = '',
        filePath,
        playerNumber,
        teams,
        userId,
        positions,
    } = player;
    return {
        id,
        lastName,
        firstName,
        enFirstName,
        enLastName,
        filePath,
        playerNumber,
        positions,
        teams,
        userId,
    };
};

export const mapEventToDatesStrings = (event: Game | Session) => {
    const start = event.start.toISOString();
    const end = event.end ? event.end.toISOString() : null;

    return {
        ...event,
        start,
        end,
    };
};

export const mapEventFromDatesStrings = (event: Game | Session) => {
    const start = new Date(event.start);
    const end = event.end ? new Date(event.end) : null;

    return {
        ...event,
        start,
        end,
    };
};

export const getPlayerLoadTime = (event: Game | Session, playerId: string) => {
    if (event.eventType === EventTypes.game) {
        const { substitutions, gameTime, lineup } = (event as Game).details;
        const lineupPosition = lineup[playerId];

        if (!lineupPosition) {
            return 0;
        }

        if (lineupPosition.time) {
            return Number(lineupPosition.time);
        }

        const isLineup = lineupPosition?.status === PlayerStatus.lineup;

        return getPlayerGameTime(substitutions, playerId, gameTime, isLineup, lineupPosition)
            .playerGameTime;
    }

    return getPlayerSessionTime(event as Session, playerId);
};

export const getEventPlayersDiff = ({
    eventPlayers,
    playersList,
}: {
    eventPlayers: EventPlayerInfo[];
    playersList: Player[];
}) => {
    const eventPlayersIds = eventPlayers.map((player) => player.id);
    const teamPlayersIds = playersList.map((player) => player.id);
    const playersIdsToAdd = teamPlayersIds.filter(
        (playerId) => !eventPlayersIds.includes(playerId),
    );
    const playersIdsToRemove = eventPlayersIds.filter(
        (playerId) => !teamPlayersIds.includes(playerId),
    );

    const playersInfoMap = new Map<string, EventPlayerInfo>(
        eventPlayers.map((eventPlayerInfo) => [eventPlayerInfo.id, eventPlayerInfo]),
    );

    /* Add missing players to the map and override some players with current player info */
    playersList.forEach((player) => {
        playersInfoMap.set(player.id, playerToEventInfo(player));
    });

    const playersIdsToUpdate = eventPlayers
        .filter((eventPlayer) => {
            const currentPlayer = playersList.find((player) => player.id === eventPlayer.id);
            if (currentPlayer) {
                const currentEventPlayer = playerToEventInfo(currentPlayer);
                return !_.isEqual(eventPlayer, currentEventPlayer);
            }
        })
        .map((player) => player.id);

    return {
        playersIdsToAdd,
        playersIdsToRemove,
        playersIdsToUpdate,
        playersInfoMap,
    };
};

export const getIsPlayerPresent = (sessionOrGame: Session | Game, playerId: string) => {
    const isSession = sessionOrGame.eventType === EventTypes.session;

    if (isSession) {
        return (sessionOrGame as Session).attendance[playerId]?.status === AttendanceStatus.present;
    }

    return [PlayerStatus.lineup, PlayerStatus.bench].includes(
        (sessionOrGame as Game).details.lineup[playerId]?.status,
    );
};
