import { AccessTarget, AccessTypes, Roles } from 'entities/access/Access';

export type Author = {
    userId: string;
    name: string;
    email: string;
    filePath: string | null;
    role: Roles | null;
};

export type UserAccessRecord = {
    targetName: string;
    role: Roles;
    target: AccessTarget;
    accessType: AccessTypes;
};

export enum Language {
    en = 'en',
    ua = 'ua',
    pl = 'pl',
    es = 'es',
    ru = 'ru',
}

export enum HelpProps {
    sawPwaInstall = 'sawPwaInstall',
    sawWelcomeGuide = 'sawWelcomeGuide',
    sawOrgPageGuide = 'sawOrgPageGuide',
    sawPlayersPageGuide = 'sawPlayersPageGuide',
    sawSessionPageGuide = 'sawSessionPageGuide',
    sawGamePageGuide = 'sawGamePageGuide',
    sawConditionsPageGuide = 'sawConditionsPageGuide',
    sawSkillsPageGuide = 'sawSkillsPageGuide',
    sawMethodologyPageGuide = 'sawMethodologyPageGuide',
    sawPlanningPageGuide = 'sawPlanningPageGuide',
}

export type UserData = {
    name: string;
    title: string;
    email: string;
    lang: Language;
    fcmTokens: string[];
    config: {
        [HelpProps.sawPwaInstall]?: boolean;
        [HelpProps.sawWelcomeGuide]?: boolean;
        [HelpProps.sawOrgPageGuide]?: boolean;
        [HelpProps.sawPlayersPageGuide]?: boolean;
        [HelpProps.sawSessionPageGuide]?: boolean;
        [HelpProps.sawGamePageGuide]?: boolean;
        [HelpProps.sawConditionsPageGuide]?: boolean;
        [HelpProps.sawSkillsPageGuide]?: boolean;
        [HelpProps.sawMethodologyPageGuide]?: boolean;
        [HelpProps.sawPlanningPageGuide]?: boolean;
        favoriteTeamId: string | null;
        showDrillPreviews: boolean;
    };
};

type User = UserData & {
    id: string;
};

export default User;
