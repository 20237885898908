import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from 'common/icons';
import { styled, SxProps } from '@mui/material/styles';
import { Box, Button, IconButton, Popover, Typography } from 'common/components';

const StyledPopover = styled(Popover)(({ theme }) => ({
    [`& .MuiPaper-root`]: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        boxShadow: theme.custom.boxShadow.main,
        borderRadius: theme.shape.borderRadius,
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        maxWidth: 300,
    },
}));

type DeleteIconButtonProps = {
    disabled?: boolean;
    submitText?: string;
    className?: string;
    sx?: SxProps;
    size?: 'small' | 'large';
    btnText?: string | null;
    onDelete: VoidFunction;
};

const DeleteIconButton: React.FC<DeleteIconButtonProps> = React.forwardRef(
    (
        { disabled, submitText, btnText, size = 'small', onDelete, ...rest },
        ref: React.ForwardedRef<HTMLButtonElement>,
    ) => {
        const { t } = useTranslation();
        const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

        const onSubmit = useCallback(() => {
            onDelete();
            setAnchorEl(null);
        }, [onDelete]);

        return (
            <>
                {!btnText && (
                    <IconButton
                        ref={ref}
                        size={size}
                        disabled={disabled || Boolean(anchorEl)}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            setAnchorEl(event.currentTarget)
                        }
                        {...rest}
                    >
                        <DeleteIcon sx={{ width: 16, height: 16 }} />
                    </IconButton>
                )}
                {btnText && (
                    <Button
                        ref={ref}
                        size="small"
                        disabled={disabled || Boolean(anchorEl)}
                        startIcon={<DeleteIcon sx={{ width: 16, height: 16 }} />}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                            setAnchorEl(event.currentTarget)
                        }
                        {...rest}
                    >
                        {btnText}
                    </Button>
                )}
                <StyledPopover
                    id="delete-submit-popover"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <Typography variant="body2" sx={{ p: 1 }}>
                        {submitText || t('deleteIcon.popupTitle')}
                    </Typography>
                    <Box sx={{ display: 'flex', m: 1, justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                                mr: 1,
                                backgroundColor: (theme: any) => theme.custom.colors.red.dark,
                            }}
                            onClick={onSubmit}
                        >
                            {t('actions.delete')}
                        </Button>
                        <Button size="small" onClick={() => setAnchorEl(null)}>
                            {t('actions.cancel')}
                        </Button>
                    </Box>
                </StyledPopover>
            </>
        );
    },
);

export default DeleteIconButton;
