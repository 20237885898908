import { addHours } from 'date-fns';
import _get from 'lodash/get';
import Drill from 'entities/drills/Drill';
import Player from 'entities/players/Player';
import { Injury } from 'entities/injuries/Injury';
import { checkInjuryByDate } from 'entities/injuries/injuries.utils';
import { getEmptyDrillsTimeByTypeMap, TimePerDrillType } from 'entities/drills/drills.utils';
import { EventTypes } from './Event';
import { playerToEventInfo } from './events.utils';
import Session, { AttendanceStatus, SessionData, SessionsSettings, SessionTypes } from './Session';

export type TeamSessionsStats = {
    totalSessionsTime: number;
    timePerDrillType: TimePerDrillType;
    generalCount: number;
    individualCount: number;
};

const green = {
    light: 'rgba(76, 175, 80, 0.12)',
    main: 'rgba(76, 175, 80, 0.54)',
    dark: 'rgba(76, 175, 80, 1)',
};

const blue = {
    light: 'rgba(0, 0, 0, 0.1) 0px 0px 7px',
    main: 'rgba(33, 150, 243, 0.54)',
    dark: 'rgba(33, 150, 243, 1)',
};

const drillsColors = {
    WARMUP: blue,
    POS: green,
    AR: green,
    TC: green,
    GC: green,
    SG: green,
    APLI: green,
    LLEG: green,
    ESP: green,
    OTHER: green,
    SETP: green,
    GPP: green,
    SPP: green,
};

export const getDrillColor = (drillType: string, style: string = 'main'): string => {
    return _get(drillsColors, `[${drillType}][${style}]`, '#000000');
};

export const defaultSessionsSettings: SessionsSettings = {
    showSessionDrills: 'nope',
    showSessionFiles: false,
    showPlayerEvaluations: false,
    showDrillDetail: false,
    showDrillGroups: false,
    showDrillRecording: false,
    showDrillVideo: false,
    showSessionLinks: false,
};

export const createEmptySessionData = (startTime?: Date, endTime?: Date): SessionData => {
    return {
        start: startTime || new Date(),
        end: endTime || addHours(new Date(), 1.5),
        eventType: EventTypes.session,
        attendance: {},
        lastUpdateAuthorUid: null,
        createDate: new Date(),
        details: {
            sessionType: SessionTypes.general,
            title: 'Session',
            playerNotes: '',
            description: '',
            drills: [],
            gkDrills: [],
            equipment: '',
            location: '',
            objectives: '',
            files: [],
            links: [],
        },
    };
};

export const createEmptySession = (
    id: string,
    organizationId: string,
    teamId: string,
    startTime?: Date,
    endTime?: Date,
): Session => {
    return {
        ...createEmptySessionData(startTime, endTime),
        id,
        teamId,
        organizationId,
    };
};

export const getPlayerSessionTime = (session: Session, playerId: string) => {
    return Number(session.attendance[playerId!]?.time) || 0;
};

export const createSessionAttendance = ({
    playersList,
    dateTime,
    teamInjuries,
}: {
    playersList: Player[];
    dateTime: Date;
    teamInjuries: Injury[];
}) => {
    return playersList.reduce((acc, player) => {
        const playerInjuries = teamInjuries.filter((injury) => injury.playerId === player.id);
        const hasInjury = checkInjuryByDate(playerInjuries, dateTime);

        return {
            ...acc,
            [player.id]: {
                status: hasInjury ? AttendanceStatus.injury : AttendanceStatus.present,
                time: 0,
                player: playerToEventInfo(player),
            },
        };
    }, {});
};

export const getTimePerDrillType = (sessions: Session[]) => {
    const drillsList: Drill[] = sessions.reduce(
        (acc: Drill[], session) => acc.concat(session.details.drills),
        [],
    );
    const initValue = getEmptyDrillsTimeByTypeMap();

    const timePerDrillType = drillsList.reduce((acc, drill) => {
        const prevDuration = acc[drill.drillType] || 0;
        return {
            ...acc,
            [drill.drillType]: prevDuration + Number(drill.duration || 0),
        };
    }, initValue);

    const totalSessionsTime = Object.values(timePerDrillType).reduce(
        (acc, duration) => acc + duration,
        0,
    );

    return {
        timePerDrillType,
        totalSessionsTime,
    };
};
