import { addDays, isAfter, isBefore } from 'date-fns';
import { Areas, Injury, InjuryData, InjuryStatus } from './Injury';

export const getLastInjury = (injuries: Injury[]) => {
    const sortedInjuries = injuries.sort((a, b) => {
        return isAfter(a.startDate, b.startDate) ? -1 : 1;
    });

    return sortedInjuries[0] || null;
};

export const getInjuriesByDate = (injuries: Injury[], date: Date) => {
    const result = injuries.filter(({ startDate, endDate }) => {
        return isAfter(date, startDate) && isBefore(date, endDate);
    });

    return result;
};

export const checkInjuryByDate = (injuries: Injury[], date: Date) => {
    return getInjuriesByDate(injuries, date).length > 0;
};

export const getInjuriesForPeriod = (injuries: Injury[], fromDate: Date, toDate: Date) => {
    const newInjuries = injuries.filter((injury) => {
        return isAfter(injury.startDate, fromDate) && isBefore(injury.startDate, toDate);
    });

    const healedInjuries = injuries.filter((injury) => {
        return isAfter(injury.endDate, fromDate) && isBefore(injury.endDate, toDate);
    });

    const activeInjuries = injuries.filter((injury) => {
        return isAfter(injury.endDate, fromDate) && !isAfter(injury.startDate, toDate);
    });

    return {
        newInjuries,
        healedInjuries,
        activeInjuries,
    };
};

export const createEmptyInjury = (playerId: string, area: Areas): InjuryData => {
    return {
        startDate: new Date(),
        endDate: addDays(new Date(), 1),
        type: 'other',
        area,
        title: '',
        description: '',
        playerId,
        status: InjuryStatus.active,
        files: [],
        createdDate: new Date(),
    };
};

export const mapInjuryFromDatesString = (injury: Injury): Injury => {
    return {
        ...injury,
        startDate: new Date(injury.startDate),
        endDate: new Date(injury.endDate),
        createdDate: new Date(injury.createdDate),
    };
};
