import { takeLatest, all, put, call, select, takeEvery } from 'redux-saga/effects';
import _get from 'lodash/get';
import * as accessGateway from 'gateways/accessGateway';
import hashFunction from 'common/utils/hashFunction';
import accessActionTypes from './access.actionTypes';
import { accessDataSelector } from './access.selectors';
import * as accessActions from './access.actions';
import AccessRecord, { AccessTarget, AccessTypes } from './Access';

const targetIdByType = {
    team: 'teamId',
    methodology: 'methodologyId',
    organization: 'organizationId',
    player: 'playerId',
    longlist: 'listId',
    shadowTeam: 'listId',
};

const generateId = ({
    accessType,
    target,
    userId,
}: {
    accessType: AccessTypes;
    target: AccessTarget;
    userId: string;
}) => {
    const targetId = _get(target, targetIdByType[accessType]);

    return hashFunction([targetId, userId].join(''));
};

export function* fetchAccessListSaga({
    target,
    accessType,
}: ReturnType<typeof accessActions.requestAccessList>) {
    try {
        const teamAccessList: AccessRecord[] = yield call(
            [accessGateway, accessGateway.fetchAccessList],
            {
                target,
                accessType,
            },
        );
        yield put(
            accessActions.accessListReceived({
                accessList: teamAccessList.map((access) => ({
                    gid: generateId(access),
                    ...access,
                })),
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* updateAccessSaga({
    accessRecord,
    onSuccess,
    onFail,
}: ReturnType<typeof accessActions.updateAccessRecord>) {
    try {
        const { accessType, target, userId } = accessRecord;
        yield call([accessGateway, accessGateway.updateAccess], accessRecord);
        yield put(accessActions.requestAccessDataAction({ accessType, target, userId }));
        yield call(onSuccess);
    } catch (e) {
        console.error(e);
        yield call(onFail);
    }
}

export function* fetchUserAccessRecordsSaga() {
    try {
        const userAccessRecords: AccessRecord[] = yield call(accessGateway.fetchUserAccessRecords);
        yield put(
            accessActions.accessListReceived({
                accessList: userAccessRecords.map((access) => ({
                    gid: generateId(access),
                    ...access,
                })),
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* fetchAccessDataSaga({
    accessType,
    target,
    userId,
}: ReturnType<typeof accessActions.requestAccessDataAction>) {
    try {
        const access: AccessRecord = yield call([accessGateway, accessGateway.fetchAccessData], {
            accessType,
            target,
            userId,
        });
        yield put(
            accessActions.accessDataReceived({
                accessRecord: {
                    gid: generateId(access),
                    ...access,
                },
            }),
        );
    } catch (e) {
        console.error(e);
    }
}

export function* deleteAccessSaga({ gid }: ReturnType<typeof accessActions.deleteAccess>) {
    const accessRecord: AccessRecord = yield select(accessDataSelector, { gid });
    try {
        yield call([accessGateway, accessGateway.deleteAccess], accessRecord);
        yield put(accessActions.removeAccessFromListAction({ gid }));
    } catch (e) {
        console.error(e);
    }
}

export function* deleteOwnAccessSaga({
    gid,
    onSuccess,
    onFail,
}: ReturnType<typeof accessActions.deleteOwnAccess>) {
    const accessRecord: AccessRecord = yield select(accessDataSelector, { gid });
    try {
        if (accessRecord) {
            const { accessType, target } = accessRecord;
            yield call([accessGateway, accessGateway.deleteOwnAccessRecord], {
                accessType,
                target,
            });
            yield put(accessActions.removeAccessFromListAction({ gid }));
        }

        yield call(onSuccess);
    } catch (e: any) {
        console.error(e);
        yield call(onFail, e.message);
    }
}

export function* requestDefaultPermissionsSaga() {
    try {
        const defaultPermissuins: Record<string, Record<string, string>> = yield call(
            accessGateway.fetchDefaultPermissions,
        );
        yield put(accessActions.defaultPermissionsReceived(defaultPermissuins));
    } catch (e) {
        console.error(e);
    }
}

export function* updateUserRoleSaga({
    userId,
    accessType,
    target,
    newRole,
    onSuccess,
    onFail,
}: ReturnType<typeof accessActions.updateUserRole>) {
    try {
        yield call(accessGateway.updateUserRole, {
            userId,
            accessType,
            target,
            newRole,
        });
        yield put(accessActions.requestAccessDataAction({ accessType, target, userId }));
        yield call(onSuccess);
    } catch (e) {
        console.error(e);

        yield call(onFail);
    }
}

function* accessSagas() {
    yield all([
        takeEvery(accessActionTypes.REQUEST_ACCESS_LIST, fetchAccessListSaga),
        takeLatest(accessActionTypes.REQUEST_ACCESS_DATA, fetchAccessDataSaga),
        takeLatest(accessActionTypes.REQUEST_USER_ACCESS_RECORDS, fetchUserAccessRecordsSaga),
        takeLatest(accessActionTypes.UPDATE_ACCESS, updateAccessSaga),
        takeLatest(accessActionTypes.DELETE_ACCESS, deleteAccessSaga),
        takeLatest(accessActionTypes.DELETE_OWN_ACCESS, deleteOwnAccessSaga),
        takeLatest(accessActionTypes.REQUEST_DEFAULT_PERMISSIONS, requestDefaultPermissionsSaga),
        takeLatest(accessActionTypes.UPDATE_USER_ROLE, updateUserRoleSaga),
    ]);
}

export default accessSagas;
