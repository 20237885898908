import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import _keyBy from 'lodash/keyBy';
import _sumBy from 'lodash/sumBy';
import User from 'entities/users/User';
import { EventTypes } from 'entities/events/Event';
import { convertUserToAuthorInfo } from 'entities/users/users.utils';
import { isBetween, ISOWeek } from 'common/utils/dateTimeHelpers';
import {
    AggType,
    ColumnConfig,
    DataProvider,
    FitnessRecordValues,
    NumericFitnessDataKeys,
    PlayerEventFitnessRecord,
    PlayerFitnessRecord,
    TeamEventFitnessRecordData,
} from './FitnessData';

export const createEventFitnessDataRecord = ({
    playersFitnessRecords,
    user,
    teamId,
    organizationId,
    eventId,
    date,
    eventType,
}: {
    playersFitnessRecords: PlayerFitnessRecord[];
    user: User;
    teamId: string;
    organizationId: string;
    eventId: string | null;
    eventType: EventTypes.game | EventTypes.session;
    date: Date;
}) => {
    const playersWithAddedEventFields = playersFitnessRecords.map((playerRecord) => ({
        ...playerRecord,
        id: nanoid(),
        date,
        eventId,
        eventType,
    }));

    const playersWithChangedKeys = Object.fromEntries(
        playersWithAddedEventFields.map((record) => [record.playerId || record.athlete, record]),
    );

    const totalDuration = playersFitnessRecords[0]?.totalTime || 0;
    const playersIds = playersWithAddedEventFields
        .map((record) => record.playerId)
        .filter(Boolean) as string[];

    const eventFitnessDataRecord: TeamEventFitnessRecordData = {
        dataProvider: DataProvider.gpExe,
        eventId,
        eventType,
        teamId,
        organizationId,
        date,
        totalDuration,
        players: playersWithChangedKeys,
        playersIds,
        author: convertUserToAuthorInfo(user)!,
    };
    return eventFitnessDataRecord;
};

export const mapFitnessDataFromDatesStrings = (fitnessData: TeamEventFitnessRecordData) => {
    const date = new Date(fitnessData.date);
    const playersArr = Object.values(fitnessData.players).map((playerData) => ({
        ...playerData,
        date: new Date(playerData.date),
    }));
    const players = _keyBy(playersArr, (player) => player.playerId || player.athlete);

    return { ...fitnessData, date, players };
};

export const getNonEmptyParameters = (playersRecordsList: FitnessRecordValues[]) => {
    return Object.values(NumericFitnessDataKeys).filter((supportedKey) => {
        return playersRecordsList.some((record) => {
            const isNotEmpty = record[supportedKey] !== undefined && record[supportedKey] !== null;
            return isNotEmpty;
        });
    });
};

export const getAvgForParam = (
    fitnessRecordsList: FitnessRecordValues[],
    key: NumericFitnessDataKeys,
) => {
    const recordsWithValues = fitnessRecordsList.filter((record) => record[key]);
    const sum = _sumBy(recordsWithValues, key);
    const result = Math.round((sum / recordsWithValues.length) * 100) / 100;

    return isNaN(result) ? null : result;
};

export const getSumForParam = (
    fitnessRecordsList: FitnessRecordValues[],
    key: NumericFitnessDataKeys,
) => {
    const recordsWithValues = fitnessRecordsList.filter((record) => record[key]);
    const result = _sumBy(recordsWithValues, key);

    return isNaN(result) ? null : result;
};

type ParamsSummaryRecord = {
    [key in NumericFitnessDataKeys]?: { avg: number | null; sum: number | null };
};

export const getSummariesMap = (
    eventRecords: FitnessRecordValues[],
    columns?: ColumnConfig[],
): ParamsSummaryRecord => {
    const parametersList = columns
        ? columns
        : getNonEmptyParameters(eventRecords).map((param) => ({ param, summary: ['avg', 'sum'] }));
    const avgValuesMap = parametersList.map(({ param, summary }) => {
        const avg = summary.includes('avg') ? getAvgForParam(eventRecords, param) : null;
        const sum = summary.includes('sum') ? getSumForParam(eventRecords, param) : null;

        return [param, { avg, sum }];
    });

    return Object.fromEntries(avgValuesMap);
};

export const aggregateFitnessRecords = (
    eventRecords: FitnessRecordValues[],
    config: { [key in NumericFitnessDataKeys]?: AggType },
) => {
    const avgValuesMap = Object.entries(config).map(([param, aggType]) => {
        if (aggType == AggType.avg) {
            const avg = getAvgForParam(eventRecords, param as NumericFitnessDataKeys);
            return [param, avg];
        }

        if (aggType == AggType.sum) {
            const sum = getSumForParam(eventRecords, param as NumericFitnessDataKeys);
            return [param, sum];
        }

        return [param, null];
    });

    return Object.fromEntries(avgValuesMap);
};

export const getEventAverageValues = (eventRecords: FitnessRecordValues[]) => {
    const parametersList = getNonEmptyParameters(eventRecords);
    const avgValuesMap = parametersList.map((columnKey) => {
        const avg = getAvgForParam(eventRecords, columnKey);

        return [columnKey, avg];
    });

    return Object.fromEntries(avgValuesMap);
};

/*
 * Acute / Chronic loads report
 */
export const getPlayerAcuteChronicValuesList = ({
    recordsList,
    weeks,
    playerId,
}: {
    recordsList: PlayerEventFitnessRecord[];
    weeks: ISOWeek[];
    playerId: string;
}) => {
    const sumsByWeekList = weeks.map((week) => {
        const records = recordsList.filter((record) =>
            isBetween(record.date, week.startDate, week.endDate),
        );
        const summaries = getSummariesMap(records);
        const sums = Object.fromEntries(
            Object.entries(summaries).map(([param, value]) => [param, value.sum]),
        );
        return { ...week, values: sums };
    });

    const rows = sumsByWeekList.map(({ weekISONumber, startDate, endDate, values }) => {
        const title = `W${weekISONumber} [ ${format(startDate, 'dd.MM')} - ${format(endDate, 'dd.MM')} ]`;
        return { title, values, weekISONumber };
    });

    const colorsMap = new Map<string, string>();
    const rowsWithAcuteChronicRatio = rows.map(({ title, values, weekISONumber }, i) => {
        const prev3Week = rows.slice(i + 1, i + 4);

        if (prev3Week.length < 3) {
            return { title, values, weekISONumber };
        }

        const summaries = getSummariesMap(prev3Week.map((week) => week.values));
        const chronicValuesMap = Object.fromEntries(
            Object.entries(summaries).map(([param, value]) => [`${param}-chronic`, value.avg]),
        );

        const acuteChronicRatioMap = Object.fromEntries(
            Object.entries(values).map(([param, value]) => {
                const avg = chronicValuesMap[`${param}-chronic`];

                if (!avg || !value) {
                    return [param, null];
                }

                const ratio = Math.round((value * 100) / avg) / 100;

                if (ratio > 1.3) {
                    colorsMap.set(`${param}-ratio-${playerId}-${weekISONumber}`, 'lightcoral');
                }

                return [`${param}-ratio`, Math.round((value * 100) / avg) / 100];
            }),
        );

        return {
            title,
            weekISONumber,
            values: { ...values, ...chronicValuesMap, ...acuteChronicRatioMap },
        };
    });

    return { weeksWithData: rowsWithAcuteChronicRatio, colorsMap };
};
