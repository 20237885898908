export enum Areas {
    general = 'general',
    head = 'head',
    body = 'body',
    pelvic = 'pelvic',
    buttockRight = 'buttockRight',
    buttockLeft = 'buttockLeft',
    groinRight = 'groinRight',
    groinLeft = 'groinLeft',
    chestRight = 'chestRight',
    chestLeft = 'chestLeft',
    calfRight = 'calfRight',
    calfLeft = 'calfLeft',
    ankleRight = 'ankleRight',
    ankleLeft = 'ankleLeft',
    neckRight = 'neckRight',
    neckLeft = 'neckLeft',
    loinRight = 'loinRight',
    loinLeft = 'loinLeft',
    backRight = 'backRight',
    backLeft = 'backLeft',
    adbomenRight = 'adbomenRight',
    adbomenLeft = 'adbomenLeft',
    thighRight = 'thighRight',
    thighLeft = 'thighLeft',
    shoulderJointRight = 'shoulderJointRight',
    shoulderJointLeft = 'shoulderJointLeft',
    shoulderRight = 'shoulderRight',
    shoulderLeft = 'shoulderLeft',
    elbowJointRight = 'elbowJointRight',
    elbowJointLeft = 'elbowJointLeft',
    forearmRight = 'forearmRight',
    forearmLeft = 'forearmLeft',
    wristRight = 'wristRight',
    wristLeft = 'wristLeft',
    handRight = 'handRight',
    handLeft = 'handLeft',
    hipRight = 'hipRight',
    hipLeft = 'hipLeft',
    kneeRight = 'kneeRight',
    kneeLeft = 'kneeLeft',
    shinRight = 'shinRight',
    shinLeft = 'shinLeft',
    footRight = 'footRight',
    footLeft = 'footLeft',
    toesRight = 'toesRight',
    toesLeft = 'toesLeft',
}

export enum InjuryStatus {
    active = 'active',
    removed = 'removed',
}

export type InjuryData = {
    startDate: Date;
    endDate: Date;
    area: Areas;
    playerId: string;
    type: string;
    title: string;
    description: string;
    files: string[];
    status: InjuryStatus;
    createdDate: Date;
};

export type Injury = InjuryData & {
    id: string;
};
